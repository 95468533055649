import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useEffect, useState } from "react"
import Layout from "../components/layout"
import IndexLayout from "../components/indexLayout"
import ArticlesGrid from "../components/articles-grid"
import Seo from "../components/seo"
import Headings from "../components/headings"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SocialIcon } from 'react-social-icons';
import { motion, AnimatePresence } from "framer-motion"
import Clock from "../components/clock"

const IndexPage = () => {
  const { allStrapiArticle, strapiGlobal } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: {fields: year, order: DESC}) {
        nodes {
          ...ArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
        indexHero {
          alternativeText
          localFile {
          childImageSharp {
            gatsbyImageData(transformOptions: { grayscale: false })
            }
          }
        }
        projectHero {
          alternativeText
          localFile {
          childImageSharp {
            gatsbyImageData(transformOptions: { grayscale: true })
            }
          }
        }
        aboutHero {
          alternativeText
          localFile {
          childImageSharp {
            gatsbyImageData(transformOptions: { grayscale: true })
            }
          }
        }
      }
    }
  `)

  const bgColors = {
    multimedia: `bg-multimedia-500`,
    art: `bg-art-400`,
    design: `bg-design-400`,
    writing: `bg-writing-500`,
    music: `bg-music-400`,
  }

  const [aboutOpen, setAboutOpen] = useState(true);
  const [gridOpen, setGridOpen] = useState(true);
  const [updatesOpen, setUpdatesOpen] = useState(true);

  const variants = {
    visible: { 
      opacity: 1,
      transition: { delay: .75, duration: 1, when: "beforeChildren", staggerChildren: .25, ease: "easeInOut" }
    },
    hidden: { 
      opacity: 0, 
      transition: { delay: .5, duration: 1, when: "afterChildren",  staggerChildren: .25, ease: "easeInOut" } 
    },
    exit: { 
      opacity: 0, 
      transition: { delay: .5, duration: 1, when: "afterChildren",  staggerChildren: .25, ease: "easeInOut" } 
    },
  }
  
  const childrenVariants = {
    visible: { 
      opacity: 1,
      transition: { delay: .5, duration: .5, ease: "easeInOut", staggerChildren: .25 } 
    },
    hidden: { 
      opacity: 0, 
      transition: { duration: .5, delay: 0, ease: "easeInOut"}
    },
  }

  const sectionVariants = {
    visible: {
      opacity: 1,
      height: "auto",
      transition: {duration: .75, delay: 0, ease: "easeInOut", staggerChildren: .25}
    },
    hidden: {
      opcacity: 0,
      height: 0,
      transition: {duration: 1, delay: 0, ease: "easeInOut", when:"afterChildren"}
    }
  }

  return (
    <IndexLayout page={ "index"}>
      <Seo seo={{ metaTitle: "home" }} />
      {/* <Headings
        title={strapiGlobal.siteName}
        description={strapiGlobal.siteDescription}
      /> */}
      <main className="font-display min-h-screen overflow-scroll bg-gray-200">
        <motion.section initial={{opacity: 0}} animate={{opacity: 1, transition: {duration: 1, delay: .25}}} className={`w-screenbg-neutral-300`}>
            <GatsbyImage
              image={getImage(strapiGlobal?.indexHero?.localFile)}
              alt={strapiGlobal?.indexHero?.alternativeText}
              className="h-[66vh] w-full"
            />
        </motion.section>
        {/*<motion.div initial="hidden" animate="visible" className="flex flex-col justify-end bottom-0 left-0 right-0 top-[3.5rem] md:top-[3.5rem] overflow-scroll">
             <motion.button variants={childrenVariants} className="px-4 py-2 text-sm text-white bg-black hover:text-white hover:bg-gray-500 transition-colors duration-[200ms] font-italics text-left w-full" onClick={() =>      {setAboutOpen(aboutOpen => !aboutOpen); setGridOpen(gridOpen => gridOpen = false); setUpdatesOpen(updatesOpen => updatesOpen = false)}}>about{aboutOpen ? ` ↴` : `…`}</motion.button>
              <AnimatePresence exitBeforeEnter>
                {aboutOpen && 
                  <section className={`bg-white text-black flex md:flex-row flex-col flex-grow h-full`}>
                    <motion.div initial="hidden" variants={sectionVariants}  animate="visible" className="py-6 px-6 text-[2rem] md:text-[3rem] break-words flex-1 flex-grow">
                      <div className="pb-5">
                        {strapiGlobal.siteDescription}
                      </div>
                      { <div className="pb-5 text-base">his work combines</div> }
                      <div className="pb-5 text-base">He graduated from Hampshire College with a B.A. in Creative Writing and Anthropology.</div>
                      
                    </motion.div>
                  </section>}
                </AnimatePresence> */}
            {/* <motion.button className="px-4 py-2 text-sm text-white bg-black md:hover:text-white md:hover:bg-gray-500 transition-colors duration-[200ms] font-italics text-left w-full" onClick={() => {setAboutOpen(gridOpen => !gridOpen)}}>about{aboutOpen ? <span class='font-mono'> ↴</span> : `…`}</motion.button>
                <AnimatePresence>
                  {aboutOpen &&
                    <motion.section variants={sectionVariants} initial="visible" animate="visible" exit="hidden" className="overflow-scroll h-full bg-neutral-100">
                      <motion.div initial='hidden' animate='visible' exit="hidden" variants={childrenVariants}>
                        <div className="px-6 py-6 flex flex-col md:flex-row gap-5 md:gap-4 content-center max-w">
                          <motion.div className="flex flex-col gap-5 text-xl md:text-2xl">
                            <div><span className="font-display font-bold">matt james</span> is an artist & writer, living in Brooklyn, NY. </div>
                            <div className="text-sm md:text-base">He works across an array of media, including drawing, watercolor, & interactive fiction.</div>
                            <div className="text-sm md:text-base">He graduated from Hampshire College with a B.A. in Anthropology & Creative Writing.</div>
                            <div className="flex flex-row gap-5">
                              <SocialIcon url="https://instagram.com/mattjames.info" bgColor="black" style={{ height: 33, width: 33 }} />
                              <SocialIcon url="https://soundcloud.com/mattjamesinfo" bgColor="black" style={{ height: 33, width: 33 }} />
                              <SocialIcon url="https://mattjames.bandcamp.com/" bgColor="black" style={{ height: 33, width: 33 }} />
                              <SocialIcon url="mailto:hello@mattjames.info" bgColor="black" style={{ height: 33, width: 33 }} />
                            </div>
                          </motion.div>
                        </div>
                      </motion.div>
                    </motion.section>}
                </AnimatePresence> */}

              {/* <motion.button className="px-4 py-2 text-sm font-italics text-white bg-black hover:text-white hover:bg-gray-500 transition-colors duration-[200ms] text-left w-full" onClick={() => {setUpdatesOpen(updatesOpen => !updatesOpen)}}>updates{updatesOpen ? ` ↴` : `…`}</motion.button>
              {updatesOpen && 
                <motion.section layout variants={sectionVariants} className="overflow-hidden h-full bg-slate-200 text-white">
                  <div variants={childrenVariants} initial="hidden" animate="visible" className="rounded py-6 px-6 mx-4 mb-4 mt-4 font-italics bg-slate-100 text-gray-500 max-h-[150px] md:max-h-[100px]">✎ Latest SubStack post: <Link to="/" className="text-sm font-italics underline underline-offset-[.4rem] hover:no-underline hover:text-black">Song Blog #4: Talk Talk is Great ↗</Link></div>
                  <div className="grid md:grid-cols-5 grid-cols-1">
                    <div className="invisible md:visible col-span-1 bg-gradient-to-l from-slate-200 to-slate-400 text-black flex place-items-center pl-8 hover:bg-gradient-to-l hover:from-slate-200 hover:to-slate-200 hover:font-normal justify-center text-5xl leading-[10rem] rounded"><span className="ml-2 text-sm font-italics">⇠ previous</span></div>
                    <div className="col-span-3">
                      <div className="group px-4 md:px-5 py-4 border-black text-black transition-colors duration-200 grid grid-cols-2 gap-0 md:gap-0 max-w-3xl mx-auto ">
                        <div className="text-sm flex place-items-center justify-start">
                          <span className="bg-slate-400 text-white px-2 py-1 rounded text-sm mr-2 col-span-1">update</span> #1
                        </div>
                        <div className="text-sm text-right flex place-items-center justify-end">
                          3.18.23
                        </div>
                        <div className="col-span-2 px-2 md:px-5 text-sm mb-2">
                          <div className="font-bold mt-2">
                            A quick change to some drawings
                          </div>
                          <span className="pr-2 text-base max-w-4xl">↳</span>Added a few more pictures to Diaster Drawings.
                        </div>
                        <div className="pt-2 float-left col-span-2 font-italics text-sm"><span className="font-italics inline-block text-sm">re:</span> <Link to="/disaster-drawings" className="text-sm font-italics underline underline-offset-[.4rem] hover:no-underline hover:text-black">Disaster Drawings →</Link>, drawing, photos</div>
                      </div>
                    </div>
                    <div className="invisible md:visible col-span-1 bg-gradient-to-r from-slate-200 to-slate-400">next update</div>
                  </div>
                </motion.section>} */}
            <motion.button className="px-4 py-2 text-sm text-white bg-black md:hover:text-white md:hover:bg-gray-500 transition-colors duration-[200ms] font-italics text-left w-full" onClick={() => {setGridOpen(gridOpen => !gridOpen)}}>work{gridOpen ? <span class='font-mono'> ↴</span> : `…`}</motion.button>
                <AnimatePresence>
                  {gridOpen &&
                      <motion.section variants={sectionVariants} initial="visible" animate="visible" exit="hidden" className="overflow-scroll h-full ">
                        {/* <motion.div initial='hidden' animate='visible' variants={childrenVariants}>
                          <ArticlesGrid articles={allStrapiArticle.nodes} />
                        </motion.div> */}
                        <motion.div initial='hidden' animate='visible' exit='hidden' variants={childrenVariants} className="grid grid-cols md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 bg-neutral-100">
                          {allStrapiArticle.nodes.filter(function(val) {
                            if (val.slug === "test") {
                              return false;
                            }
                              return true;
                          }).map((article) => (
                            <motion.div initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={{ duration: 1 }}
                            variants={{
                              visible: { opacity: 1 },
                              hidden: { opacity: 0 }
                            }} className="grid grid-cols h-[40vh] md:h-[40vh]">
                              <Link
                            to={`/${article.slug}`}
                            className={`group overflow-hidden hover:${bgColors[article.category]} transition-colors duration-[100ms] hover:shadow-md`}>
                            <div className="px-4 py-4">
                              <div className="flex justify-between place-items-center gap-2">
                                <h3 className="font-italics group-hover:text-white">{article.title}</h3>
                            </div>
                            {/* <div className="py-1 text-xs md:text-sm">
                              <p>{article.description}</p>
                            </div> */}
                            <div className="flex flex-wrap justify-start gap-2 mt-2 z-10">
                                <p className={`rounded text-white group-hover:outline group-hover:outline-1 group-hover:outline-white group-hover:text-white px-2 py-1 text-[.5rem] min-w-[8ch] text-center ${bgColors[article.category]}`}>{article.category}</p>
                                <p className="rounded outline outline-1 group-hover:outline-white outline-black group-hover:text-white text px-1 py-1 text-[.5rem] min-w-[8ch] text-center">{article.medium}</p>
                                <p className="rounded outline outline-1 group-hover:outline-white outline-black group-hover:text-white text px-1 py-1 text-[.5rem]">{article.inProgress === true ? <div>{article.year} to present</div> : <div>{article.year}</div>}</p></div>
                              </div>
                            <GatsbyImage
                              image={getImage(article.cover?.localFile)}
                              alt={article.cover?.alternativeText}
                              className={`opacity-50 group-hover:opacity-90 transition-opacity duration-[100ms] w-full h-full`}
                            />
                          </Link>
                             </motion.div>
                  
                           ))}
                        </motion.div>
                      </motion.section>
                      }
                </AnimatePresence>
                    
                  
            
                {/*  */}
          
          
      </main>
    </IndexLayout>
  )
}

export default IndexPage
