import React from "react"
import Footer from "./footer"
import Navbar from "./navbar"
import { motion } from "framer-motion"

const IndexLayout = ({ children, page }) => {

  const layoutVariants = {
    visible: { 
      opacity: 1,
      transition: { duration: .33 }
    },
    hidden: { 
      opacity: 0,
    },
    exit: { 
      opacity: 0,
      transition: { duration: 1 }
    },
  }

  return (
    <div className="min-h-screen text-neutral-900 sm:scrollbar-hide">
      <div>
        <Navbar page={page} />
        <motion.div initial="hidden" animate="visible" exit="exit" variants={layoutVariants} className="scrollbar-hide">{children}</motion.div>
      </div>
    </div>
  )
}

export default IndexLayout
